import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Callout = ({ title, ctas }) => {
  return (
    <div className="container mt-12">
      <div className="bg-purple-300 py-10">
        <div className="max-w-md mx-auto">
          <div className="text-white text-center mb-6">{title}</div>
          <ul className="flex flex-col md:flex-row justify-between items-center">
            {ctas.includes(`faq`) && (
              <li className="mb-4 md:mb-0">
                <Link
                  aria-label="frequently asked questions"
                  className="btn btn-gray"
                  to="/faq"
                >
                  FAQ<span className="lowercase">s</span>
                </Link>
              </li>
            )}
            {ctas.includes(`contact`) && (
              <li>
                <Link className="btn btn-gray" to="/contact">
                  Contact Us
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

Callout.defaultProps = {
  ctas: [`faq`, `contact`],
  title: `Still have questions?`,
}

Callout.propTypes = {
  ctas: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default Callout
