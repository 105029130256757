import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Callout from '../components/callout'

const ServiceInsidePage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Residential Consultation" />
      <main className="max-w-4xl mx-auto px-4 pt-3 lg:pt-12 mb-12">
        <h1 className="text-lg lg:text-2xl text-center mb-6">
          Residential Consultation
        </h1>
        <p className="text-center lg:text-left text-sm lg:text-base mb-6 lg:mb-12">
          Tristique magnis purus etiam nulla ut libero orci fusce aliquet
          elementum, habitant felis tincidunt facilisi leo imperdiet enim
          integer nam, lectus risus faucibus taciti curabitur lorem odio
          dictumst non.
        </p>
        <ul>
          <li className="mb-3 lg:mb-12">
            <Img className="shadow" fluid={data.img1.childImageSharp.fluid} />
          </li>
          <li className="mb-3 lg:mb-12">
            <Img className="shadow" fluid={data.img2.childImageSharp.fluid} />
          </li>
          <li className="mb-3 lg:mb-12">
            <Img className="shadow" fluid={data.img3.childImageSharp.fluid} />
          </li>
        </ul>
      </main>
      <aside>
        <ul className="lg:flex">
          <li className="lg:w-1/2 bg-gray-300 flex items-end py-10 px-4 lg:pt-40 relative">
            <div className="max-w-sm mx-auto p-4 shadow z-10 relative bg-white-transparent">
              <h2 className="text-base lg:text-lg mb-2">Creative Designs</h2>
              <p className="text-xs mb-6">
                Per vel mollis pretium euismod ultricies malesuada interdum nunc
                nulla donec, non pulvinar rutrum a dis elementum nam lectus
                pellentesque taciti sollicitudin.
              </p>
              <Link className="btn btn-purple" to="/">
                Learn More
              </Link>
            </div>
            <Img
              className="top-0 left-0 w-full h-full"
              fluid={data.img4.childImageSharp.fluid}
              style={{ position: `absolute` }}
            />
          </li>
          <li className="lg:w-1/2 bg-tan flex items-center py-10 px-4 lg:px-0">
            <div className="max-w-sm lg:pl-24">
              <h2 className="text-base lg:text-lg mb-2">Delivery</h2>
              <p className="text-xs mb-6">
                Per vel mollis pretium euismod ultricies malesuada interdum nunc
                nulla donec, non pulvinar rutrum a dis elementum nam lectus
                pellentesque taciti sollicitudin.
              </p>
              <Link className="btn btn-purple" to="/">
                Learn More
              </Link>
            </div>
          </li>
        </ul>
        <Callout />
      </aside>
    </Layout>
  )
}

export const query = graphql`
  query {
    img1: file(relativePath: { eq: "sample-remove-later/img1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img2: file(relativePath: { eq: "sample-remove-later/img2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img3: file(relativePath: { eq: "sample-remove-later/img3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img4: file(relativePath: { eq: "sample-remove-later/frames3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default ServiceInsidePage
